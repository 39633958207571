import { Button, InputAdornment, Stack, TextField, ThemeProvider } from '@mui/material';
import React, { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from './theme';

type inputFieldProps = {
    icon: ReactNode | null;
    name: string;
    helperText: string | null;
    error: boolean;
    onChange: any;
    value: any;
    label: string;
    input?: any;
    meta?: any;
};
const RenderFileInputField: React.FC<inputFieldProps> = ({ name, input, onChange, error, label, value, icon, helperText }) => {
    const { t } = useTranslation('common');
    const fileInputRef = useRef<HTMLInputElement>(null);

    /** Click in TextField triggers a click on input field */
    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    /** Handling file upload */
    const handleChange = (e) => {
        e.preventDefault();
        const files = [...e.target.files];
        onChange({ file: files[0] ? files[0] : null, name: files[0] && files[0].name ? files[0].name : '' });
    };
    return (
        <ThemeProvider theme={theme}>
            <Stack direction="row" alignItems="center" spacing={2} sx={{ position: 'relative' }}>
                <TextField
                    className={`${error ? 'Mui-error' : ''}`}
                    onChange={onChange}
                    fullWidth
                    disabled={value?.name ? true : false}
                    value={value?.name ?? label}
                    label={label}
                    error={error}
                    onClick={handleClick}
                    InputProps={
                        icon
                            ? {
                                  startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
                              }
                            : undefined
                    }
                    {...input}
                />
                <Button variant="contained" className="btn__secondary" component="label" sx={{ margin: '0 !important', position: 'absolute', right: '10px' }}>
                    {t('claim.choose_file')}
                    <input hidden ref={fileInputRef} id={`file-input-${value.name}`} accept="*/*" multiple type="file" onChange={handleChange} />
                </Button>
            </Stack>
            {/* {error && <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">{helperText}</p>} */}
        </ThemeProvider>
    );
};

export default RenderFileInputField;
//{t('validations.required')}
