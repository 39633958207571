/* eslint-disable no-console */
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';

import { authReducer, errReducer, uiReducer } from './index';
import claimReducer from './claim/reducer';

const sagaMiddleWare = createSagaMiddleware();
const reducer = combineReducers({
    auth: authReducer,
    claim: claimReducer,
    ui: uiReducer,
    err: errReducer,
});

const logger = (store) => {
    return (next) => {
        return (action) => {
            // console.log('[Middleware] Dispatching', action);
            const result = next(action);
            // console.log('[Middleware] next state', store.getState());
            return result;
        };
    };
};

const composeEnhancers = (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;
const store = createStore(reducer, composeEnhancers(applyMiddleware(sagaMiddleWare, logger, thunk)));
sagaMiddleWare.run(rootSaga);
export default store;
