import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { SvgIconProps, ThemeProvider, Typography } from '@mui/material';
import { theme } from './theme';
import { useTranslation } from 'react-i18next';

interface DatePickerProps {
    onChange?: any;
    placeholder?: string;
    minDate?: Dayjs;
    maxDate?: Dayjs;
    input?: any;
    error: boolean;
    disabled?: boolean;
    icon?: SvgIconProps;
    value?: any;
    popperPlacement?: string;
    meta?: any;
    label?: string;
    variant?: any;
}
const RenderDatePickerField = ({ onChange, error, label, value, minDate, maxDate, ...props }: DatePickerProps) => {
    const { t } = useTranslation('common');
    const [defaultValue, setDefaultValue] = React.useState<Dayjs | null>(null);
    const handleChange = (newValue: Dayjs) => {
        setDefaultValue(newValue);
        onChange(newValue);
    };
    return (
        <div className={`datepicker ${error && 'error'}`}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                        <DesktopDatePicker minDate={minDate} maxDate={maxDate} label={label} format="DD-MM-YYYY" value={value ?? defaultValue} onChange={handleChange} />
                        {error && (
                            <Typography
                                sx={{ margin: '3px 14px 0 14px !important', fontSize: '0.75rem' }}
                                variant="body1"
                                className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
                            >
                                {t('validations.required')}
                            </Typography>
                        )}
                    </Stack>
                </LocalizationProvider>
            </ThemeProvider>
        </div>
    );
};
export default RenderDatePickerField;

// const MaterialMobileDatePicker = ({ onChange, icon, input, label, variant, minDate, maxDate, disabled, ...custom }) => {
//     const [value, setValue] = React.useState<Dayjs | null>();

//     const handleChange = (newValue: Dayjs) => {
//         // const formatDate = dayjs(new Date(newValue));
//         setValue(newValue);
//         onChange(newValue);
//     };
//     const onKeyDown = (e) => {
//         e.preventDefault();
//     };

//     return (
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//             <Stack spacing={3}>
//                 <InputAdornment position="start">{icon}</InputAdornment>
//                 <DesktopDatePicker
//                     minDate={minDate}
//                     maxDate={maxDate}
//                     label={label}
//                     format="DD/MM/YYYY"
//                     // inputFormat="DD/MM/YYYY"
//                     // value={value}
//                     onChange={handleChange}
//                     // renderInput={(params) => <TextField onKeyDown={onKeyDown} />}
//                 />
//             </Stack>
//         </LocalizationProvider>
//     );
// };
