import EditIcon from 'mdi-react/EditIcon';
import SaveIcon from 'mdi-react/FloppyIcon';
import React, { useEffect, useState } from 'react';
import { Box, Button, CardHeader, FormControl, Grid, TextField } from '@mui/material';
import RenderTextField from '../Form/TextField';
import { useTranslation } from 'react-i18next';
import ContractIcon from '@mui/icons-material/Article';
import { validateRealIBAN } from '../Utility/util';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import Service from '@src/api/api';
import { setNotification } from './Notification';
import { useDispatch } from 'react-redux';
import { GET_ACCOUNT } from '@src/redux/auth/actions';

type formValues = {
    ibanNumber: string;
};

const ContractCard = ({ contract, account }) => {
    const [editBankAccount, setEditBankAccount] = useState<boolean>(false);
    const [editedIBAN, setEditedIBAN] = useState(null);
    const [IBANResult, setIBANResult] = useState<unknown>(true);

    const dispatchStore = useDispatch();

    const { t } = useTranslation('common');
    const form = useForm<formValues>({
        defaultValues: {
            ibanNumber: account?.ibanNumber ?? '',
        },
        mode: 'onSubmit',
    });
    const { register, control, handleSubmit, formState, watch, reset } = form;
    const { errors, isSubmitSuccessful } = formState;

    const onSubmit = (data: formValues) => {
        const partnerId = sessionStorage.getItem('partnerId') as string;
        const contractNumber = sessionStorage.getItem('contractNumber') as string;
        const payLoad = [
            {
                action: 'UPDATE',
                field: 'ibanNumber',
                value: data?.ibanNumber,
            },
        ];
        Service.updateAccount(partnerId, contractNumber, payLoad)
            .then((response) => {
                setNotification(t('error_handling.success'), t('dashboard.contract_updated'), 'success');
                setEditBankAccount(false);
                dispatchStore({ type: GET_ACCOUNT, val: true });
            })
            .catch((err) => {
                console.log('ERR RESPONSE', err.response);
                setNotification('Error', t('dashboard.contract_not_updated'), 'danger');
            });
    };
    const onError = (errors: FieldErrors<formValues>) => {
        // console.log('Form Errors', errors);
    };

    // const ibanNumber = watch('ibanNumber');
    // useEffect(() => {
    //     if (isSubmitSuccessful) {
    //         reset();
    //     }
    // }, [isSubmitSuccessful]);

    // const { handleSubmit, reset, control, setValue } = useForm<any>();
    return (
        <Box sx={{ border: '1px solid rgba(0,0,0,0.175)', borderRadius: '5px' }}>
            <CardHeader sx={{ backgroundColor: '#dddd', textTransform: 'uppercase' }} avatar={<ContractIcon />} title={t('dashboard.contract')} />
            <Grid container justifyContent={'center'} sx={{ padding: '1rem 0' }} spacing={1}>
                <Grid item xs={10}>
                    <RenderTextField disabled={true} value={contract.customerName ?? t('dashboard.unknown')} label={t('dashboard.customer_name')} />
                </Grid>
                <Grid item xs={10} sx={{ margin: '.5rem 0' }}>
                    <RenderTextField disabled={true} value={contract.email ?? t('dashboard.unknown')} label={t('dashboard.email')} />
                </Grid>
                <Grid item xs={10} sx={{ margin: '.5rem 0' }}>
                    <RenderTextField disabled={true} value={contract.street ?? t('dashboard.unknown')} label={t('dashboard.street')} />
                </Grid>
                <Grid item xs={10} lg={5} sx={{ margin: '.5rem 0' }}>
                    <RenderTextField disabled={true} value={contract.streetNumber ?? t('dashboard.unknown')} label={t('dashboard.street_number')} />
                </Grid>
                <Grid item xs={10} lg={5} sx={{ margin: '.5rem 0' }}>
                    <RenderTextField disabled={true} value={contract.postcode ?? t('dashboard.unknown')} label={t('dashboard.postcode')} />
                </Grid>
                <Grid item xs={10} sx={{ margin: '.5rem 0' }}>
                    <RenderTextField disabled={true} value={contract.city ?? t('dashboard.unknown')} label={t('dashboard.city')} />
                </Grid>
                {/* <Grid item xs={10} sx={{ margin: '.5rem 0' }}>
                    <RenderTextField disabled={true} value={contract.city ?? t('dashboard.unknown')} label={t('dashboard.city')} />
                </Grid> */}

                {editBankAccount == false ? (
                    <Grid item xs={10} sx={{ margin: '.5rem 0', display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        <RenderTextField disabled={true} value={account?.ibanNumber ?? ''} label={t('dashboard.iban')} />

                        <Button className="btn__primary" sx={{ height: '55px', justifyContent: 'flex-end' }} type="button" onClick={() => setEditBankAccount(true)}>
                            {t('layout.edit_iban')}
                        </Button>
                    </Grid>
                ) : (
                    ''
                )}

                {editBankAccount == true ? (
                    <Grid item xs={10} sx={{ margin: '.5rem 0' }}>
                        <form onSubmit={handleSubmit(onSubmit, onError)} style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                            <FormControl sx={{ width: '100%' }}>
                                <Controller
                                    name="ibanNumber"
                                    control={control}
                                    rules={{
                                        required: t('validations.required'),
                                        validate: {
                                            validateIban: validateRealIBAN,
                                            validateRealIban: validateRealIBAN,
                                        },
                                    }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <RenderTextField
                                            error={!!error}
                                            helperText={error?.message ?? null}
                                            disabled={!editBankAccount}
                                            onChange={onChange}
                                            value={value ?? ''}
                                            label={t('dashboard.iban')}
                                        />
                                    )}
                                />
                            </FormControl>

                            <Button className="btn__primary" sx={{ height: '55px', justifyContent: 'flex-end' }} type={'submit'}>
                                {t('layout.save_iban')}
                            </Button>
                        </form>
                    </Grid>
                ) : (
                    ''
                )}
            </Grid>
        </Box>
    );
};

export default ContractCard;
