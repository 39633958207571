import i18next from 'i18next';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter, RouterProvider } from 'react-router-dom';

import store from '../redux/store';
import { config as i18nextConfig } from '../translations/index';
import { router } from './router';

// const getData = useSelector(
// 	(state: any) => state?.form?.loginform?.values.selectedLanguage.value,
// );
// i18nextConfig.lng = getData;
i18next.init(i18nextConfig);

//const LazyStrawberryIcon = lazy(() => import('./strawberry'));
export const App = (): React.ReactElement => {
    return (
        <Provider store={store}>
            <I18nextProvider i18n={i18next}>
                <RouterProvider router={router} />
                {/* <Router />
                </RouterProvider> */}
            </I18nextProvider>
        </Provider>
    );
};
