import { Box, Button, CardMedia, Grid, Typography } from '@mui/material';
import RootState from '@src/interfaces/RootState';
import { GET_ACCOUNT } from '@src/redux/auth/actions';
import { RESET_CLAIM } from '@src/redux/claim/actions';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Success = () => {
    const { t } = useTranslation('common');
    const dispatchStore = useDispatch();
    const responseClaimNumber = useSelector((state: RootState) => state?.claim.claimNumber);

    useEffect(() => {
        return () => {
            dispatchStore({ type: RESET_CLAIM });
            // dispatchStore({ type: GET_ACCOUNT, val: true });
        };
    }, []);
    return (
        <Box className="success__container" sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid container justifyContent={'center'} sx={{ margin: '1rem 0' }}>
                <CardMedia sx={{ width: '100%', objectFit: 'contain', padding: '20%' }} component="img" height="100%" image="/img/success.svg" />
            </Grid>
            <Grid container justifyContent={'center'} sx={{ margin: '1rem 0' }}>
                <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    {/* <CardMedia sx={{ maxHeight: '40px', width: '100%', objectFit: 'contain' }} component="img" alt="green iguana" height="100%" image="/img/alternatelogo.png" /> */}
                    <Typography sx={{ fontSize: '16px', margin: '.5rem', fontWeight: 'bolder' }} variant="h2">
                        {t('success.title')}
                    </Typography>
                    <Box sx={{ margin: '2rem', backgroundColor: '#ad0109', color: '#f8f8f8', padding: '2px 5px', borderRadius: '5px' }}>
                        <Typography variant="body1">{responseClaimNumber}</Typography>
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Link to="/dashboard">
                            <Button className="btn__secondary">{t('layout.dashboard')}</Button>
                        </Link>
                        <Link to="/claim-history">
                            <Button className="btn__secondary">{t('layout.claim_history')}</Button>
                        </Link>
                    </Box>
                </Grid>
                {/* <Grid item xs={10} sx={{ padding: '20%', display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' } }}>
                </Grid> */}
                {/* <Grid item xs={10} md={5} sx={{ margin: '.5rem 0' }}>
                </Grid> */}
            </Grid>
        </Box>
    );
};

export default Success;
