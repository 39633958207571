import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import EndUserLogin from '@src/components/Login/EndUserLogin';
import { Box, CardMedia, Grid } from '@mui/material';
import { SET_LANGUAGE } from '@src/redux/ui/actions';

const Login: React.FC = () => {
    const { t } = useTranslation('common');
    const dispatchAction = useDispatch();

    useEffect(() => {
        if (!localStorage.getItem('language')) {
            localStorage.setItem('language', 'en');
            dispatchAction({ type: SET_LANGUAGE, val: 'en' });
        } else {
            const currentLanguage = localStorage.getItem('language') as string;
            dispatchAction({ type: SET_LANGUAGE, val: currentLanguage });
        }
    }, []);

    return (
        <Box>
            <Grid container sx={{ margin: 'auto' }}>
                <Grid item xs={12} md={6} sx={{ height: { xs: '15vh', md: '100vh' }, display: 'flex' }}>
                    <CardMedia
                        sx={{ maxHeight: '300px', width: '100%', maxWidth: '500px', margin: 'auto', objectFit: 'contain', padding: '5%' }}
                        component="img"
                        alt="green iguana"
                        height="100%"
                        image="../img/alternatelogo.png"
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ height: { xs: '60vh', md: '100vh' }, display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: { xs: '90%', md: '90%', xl: '50%' }, margin: { xs: 'auto', md: '0' } }}>
                        <EndUserLogin />
                    </Box>
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'} sx={{ position: 'absolute', bottom: '0', left: '0' }}>
                <Grid item xs={10} sx={{ padding: '1rem', borderTop: '1px solid #c4c5c7', display: 'flex', justifyContent: 'center' }}>
                    <Link to={'/contact'}>{t('login.contact')}</Link>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Login;
