/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import RootState from '@src/interfaces/RootState';
import { LOG_OUT, REFRESH_TOKEN } from '@src/redux/auth/actions';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const PrivateRoute: React.FC = () => {
    const isAuthenticated = useSelector((state: RootState) => state?.auth?.signedIn);
    const dispatchStore = useDispatch();

    /** When user is inactive */
    const onIdle = () => {
        console.log('Inactive user logged out');
        dispatchStore({ type: LOG_OUT });
    };

    const onActive = () => {
        return;
    };

    const onAction = () => {
        return;
    };

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
        onAction,
        timeout: 3 * 3 * 60 * 1000,
        throttle: 500,
    });

    useEffect(() => {
        dispatchStore({ type: REFRESH_TOKEN });
        const tokenRefreshInterval = setInterval(() => {
            /** Token Refresh */
            console.warn('Token refresh');
            dispatchStore({ type: REFRESH_TOKEN });
        }, 3 * 60 * 1000); // 1 perc * 60 másodperc * 1000 milliszekundum

        return () => {
            clearInterval(tokenRefreshInterval);
        };
    }, []);
    return isAuthenticated ? <Outlet /> : <Navigate to="/" replace />;
};

export default PrivateRoute;
