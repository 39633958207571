import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Box, CircularProgress, ThemeProvider } from '@mui/material';
import { Backdrop } from '@mui/material';
import { useSelector } from 'react-redux';
import RootState from '@src/interfaces/RootState';
import { theme } from '../Form/theme';

interface Loading {
    forceLoading?: boolean;
}
const LoadingScreen: React.FC<Loading> = ({ forceLoading }) => {
    /* STATES */

    /* VARIABLES */
    const { t } = useTranslation('common');
    const isLoading = useSelector((state: RootState) => state.ui.isLoading);

    /* FUNCTIONS */
    const content = (
        <ThemeProvider theme={theme}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={forceLoading ?? isLoading}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CircularProgress sx={{ margin: '1rem auto', color: '#d9b700' }} />
                    <Typography align="center" color="white" className="loading__title">
                        {t('error_handling.please_wait')}
                    </Typography>
                </Box>
            </Backdrop>
        </ThemeProvider>
    );

    /* USEEFFECTS */

    return <>{ReactDOM.createPortal(content, document.getElementById('loader-hook')!)}</>;
};

export default LoadingScreen;
