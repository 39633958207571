const dateFormatter = (target) => {
    return (
        `${target.getFullYear()}-${`${target.getMonth() + 1}`.toString().length === 1 ? `0${target.getMonth() + 1}` : `${target.getMonth() + 1}`}-` +
        `${target.getDate().toString().length === 1 ? `0${target.getDate()}` : target.getDate()}`
    );
};

export default dateFormatter;

const validateIBAN = (value) => {
    const isValidIBAN = /^BE[0-9]{14}$/i.test(value);
    console.log('file: index.tsx:134 ~ validateIBAN ~ isValidIBAN:', isValidIBAN);

    return isValidIBAN || 'Invalid IBAN';
};

export const getClaimStatus = (code) => {
    switch (code) {
        case 5:
            return 'claimhistory.customer_sent';
        case 10:
            return 'claimhistory.telephone_call';
        // case 20:
        //     return 'claimhistory.damage_report_sent';
        // case 25:
        //     return 'claimhistory.damage_report_recieved';
        case 30:
            return 'claimhistory.pre_approved';
        case 60:
            return 'claimhistory.invoicing';
        case 70:
            return 'claimhistory.finalized';
        case 80:
            return 'claimhistory.refused';
        case 90:
            return 'claimhistory.abandoned';
        default:
            return 'claimhistory.status_under_process';
    }
};

export const validateRealIBAN = (value: string): string | boolean => {
    const ibanArray: string[] = [...value];
    const ibanValidateNumber: string = '' + ibanArray[2] + ibanArray[3];
    const ibanArrayNew: string[] = ibanArray.slice(4);
    ibanArrayNew.push('11');
    ibanArrayNew.push('14');
    ibanArrayNew.push(ibanValidateNumber);
    const ibanNew: string = ibanArrayNew.join('');
    const firstPart: string = ibanNew.slice(0, 9);
    const secondPart: string = ibanNew.slice(9, 16);
    const thirdPart: string = ibanNew.slice(16, 19);
    const mod1: number = (parseInt(firstPart) * 1) % 97;
    const secondParts: string = '' + mod1 + secondPart;
    const mod2: number = (parseInt(secondParts) * 1) % 97;
    const thirdParts: string = '' + mod2 + thirdPart;
    const finalScore: number = parseInt(thirdParts) % 97;

    return finalScore === 1 ? true : 'IBAN not valid';
};
