import { Box, Button, CardMedia, Grid, Typography } from '@mui/material';
import FileIcon from 'mdi-react/FileDocumentEditOutlineIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Contact = () => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const goToLogin = () => {
        navigate('/');
    };
    return (
        <Grid container>
            <Grid item xs={10} sx={{ margin: 'auto', minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="contact-wrapper">
                <Box sx={{ backgroundColor: '#dddd', padding: '2rem', borderRadius: '15px' }}>
                    <Typography variant="h4" component="h1" sx={{ textAlign: 'center', marginBottom: '1.5rem' }}>
                        {t('contact.contact_us')}
                    </Typography>
                    <Grid container justifyContent={'space-around'}>
                        <Grid item xs={12} md={4} sx={{ display: { xs: 'none', md: 'block' }, padding: '1rem' }}>
                            <CardMedia component="img" height="100%" image="/img/contact_us.svg" />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', marginBottom: '.5rem' }}>
                                <Typography variant="body1">{t('contact.question_website')}:</Typography>
                                <Box sx={{ color: '#ad0109', display: 'inline' }}>
                                    <Link to="mailto:info@spb.be">info@spb.be</Link>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', marginBottom: '.5rem' }}>
                                <Typography variant="body1">{t('contact.question_claim')}:</Typography>
                                <Box sx={{ color: '#ad0109', display: 'inline' }}>
                                    <Link to="mailto:Alternate.claims@spb.be">Alternate.claims@spb.be</Link>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', margin: '1rem', marginTop: { sx: '1.5rem', md: 'auto' } }}>
                                <Button className="btn__primary contact_btn" onClick={() => goToLogin()}>
                                    {t('contact.return')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Contact;
