import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axiosRequestConfiguration } from './config';

const tokenFunction = () => {
    const token = sessionStorage.getItem('token_alternate');
    return `${token}`;
};

const checkNumberFunction = () => {
    const checkNumber = sessionStorage.getItem('checkNumber') as string;
    return checkNumber;
};

const initialization = (configs: AxiosRequestConfig): AxiosInstance => {
    const axiosInstance = axios.create(configs);
    axiosInstance.interceptors.request.use(async (request) => {
        if (request.url?.includes('claim-registration')) {
            request.headers.Authorization = tokenFunction();
            request.headers.requestId = checkNumberFunction();
            request.headers.SCH_ID = 'N_ALTERNATE';
            return request;
        }
        if (!request.url?.includes('login') && !request.url?.includes('verify') && !request.url?.includes('password/forgotten')) {
            request.headers.Authorization = tokenFunction();
            request.headers.SCH_ID = 'N_ALTERNATE';
            return request;
        }
        return request;
    });

    return axiosInstance;
};

const axiosInstance = initialization(axiosRequestConfiguration);

export default axiosInstance;
