/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CHANGE_MOBILE_SIDEBAR_VISIBILITY, CHANGE_SIDEBAR_VISIBILITY, SET_ACTUAL_CONTRACT, SET_BACKDROP, SET_LANGUAGE, SET_LOADING } from './actions';

export const initialState = {
    show: false,
    collapse: false,
    isLoading: false,
    isBackdrop: false,
    actualContract: {},
    language: 'en',
};

export default function uiReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_SIDEBAR_VISIBILITY:
            return { ...state, collapse: !state.collapse };
        case CHANGE_MOBILE_SIDEBAR_VISIBILITY:
            return { ...state, show: !state.show };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.val,
            };
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.val,
            };
        case SET_BACKDROP:
            return {
                ...state,
                isBackdrop: action.val,
            };
        case SET_ACTUAL_CONTRACT:
            return {
                ...state,
                actualContract: action.val,
            };
        default:
            return state;
    }
}
