/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from 'react-router';

import { validateRealIBAN } from '@src/components/Utility/util';
import PhoneIcon from 'mdi-react/TelephoneIcon';
import MoneyIcon from 'mdi-react/EuroIcon';
import BankIcon from 'mdi-react/BankIcon';
import InvoiceIcon from 'mdi-react/InsertDriveFileIcon';
import RepairInvoiceIcon from 'mdi-react/NoteAddIcon';
import QuotationIcon from 'mdi-react/AssignmentIcon';
import DescriptionIcon from 'mdi-react/CommentAddIcon';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import RenderTextField from '@src/components/Form/TextField';
import RenderDatePickerField from '@src/components/Form/DatePicker';
import { SAVE_CLAIM_FORM } from '@src/redux/claim/actions';
import ClaimModel from '@src/api/models/Claim';
import RenderFileInputField from '@src/components/Form/FileInput';

import { DevTool } from '@hookform/devtools';
import dayjs, { Dayjs } from 'dayjs';
import { REFRESH_TOKEN } from '@src/redux/auth/actions';
import RootState from '@src/interfaces/RootState';

const newClaim: React.FC = () => {
    const { t } = useTranslation('common');
    const sessionData = JSON.parse(sessionStorage.getItem('selectedContract')!);
    const [fileInvoice, setFileInvoice] = useState<boolean>(false);
    const [filefileQuotation, setFileQuotatione] = useState<boolean>(false);
    const [fileRepairInvoice, setFileRepairInvoice] = useState<boolean>(false);
    const [submitFailedOnForm, setSubmitFailedOnForm] = useState<boolean>(false);
    const dispatchStore = useDispatch();

    const claimFormData = useSelector((state: RootState) => state?.claim.claimForm);

    const storedAccount = JSON.parse(sessionStorage.getItem('account') as string);

    const token = sessionStorage.getItem('token_alternate') as string;

    const options = useMemo(
        () => [
            {
                label: t('claim.read_manual'),
                value: '1',
                name: 'readManual',
            },
            {
                label: t('claim.restart_device'),
                value: '2',
                name: 'restartDevice',
            },
            {
                label: t('claim.functional_restriction'),
                value: '3',
                name: 'functionalRestriction',
            },
        ],
        []
    );
    const {
        handleSubmit,
        getValues,
        control,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            breakdownDate: claimFormData?.breakdown_date ? dayjs(new Date(claimFormData?.breakdown_date)) : null,
            invoiceDate: claimFormData?.invoice_date ? dayjs(new Date(claimFormData?.invoice_date)) : null,
            customerPhone: claimFormData?.customer_phone ?? null,
            invoiceAmount: claimFormData?.invoice_amount ?? null,
            bankAccount: claimFormData?.bank_account ?? storedAccount?.ibanNumber ?? '',
            fileInvoice: claimFormData?.fileInvoice ?? undefined,
            fileQuotation: claimFormData?.fileQuotation ?? undefined,
            fileRepairInvoice: claimFormData?.fileRepairInvoice ?? undefined,
            claimDescription: claimFormData?.clm_description ?? '',
        },
        mode: 'onSubmit',
    });
    const fileFields = watch(['fileInvoice', 'fileQuotation', 'fileRepairInvoice']);

    const navigate = useNavigate();
    const onSubmit = (data) => {
        /** Compile payload */
        const newClaim = new ClaimModel(data, sessionData);
        // console.log('file: index.tsx:72 ~ onSubmit ~ newClaim:', newClaim);

        dispatchStore({ type: SAVE_CLAIM_FORM, val: newClaim });
        navigate('/summary');
        // dispatchStore({ type: CLAIM_ADD, val: newClaim });
    };

    useEffect(() => {
        if (getValues('fileInvoice') !== undefined) setFileInvoice(true);
        if (getValues('fileQuotation') !== undefined) setFileQuotatione(true);
        if (getValues('fileRepairInvoice') !== undefined) setFileRepairInvoice(true);
    }, [fileFields]);

    useEffect(() => {
        if (Object.keys(errors).length === 0) setSubmitFailedOnForm(false);
        else setSubmitFailedOnForm(true);
    }, [errors]);

    useEffect(() => {
        dispatchStore({ type: REFRESH_TOKEN });
    });

    return (
        <Box sx={{ position: 'relative', minHeight: '100vh', padding: '5%' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container justifyContent={'center'} sx={{ margin: '1rem 0' }}>
                    <Grid item sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' } }}>
                        {/* <CardMedia sx={{ maxHeight: '40px', width: '100%', objectFit: 'contain' }} component="img" alt="green iguana" height="100%" image="/img/alternatelogo.png" /> */}
                        <Typography sx={{ fontSize: '32px', margin: '.5rem', textTransform: 'uppercase', fontWeight: 'bolder' }} variant="h2">
                            {t('claim.title')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{ width: '90%', margin: 'auto' }}>
                    <Grid item xs={12} md={6} sx={{ padding: '1rem' }}>
                        <Controller
                            name="breakdownDate"
                            control={control}
                            rules={{
                                required: { value: true, message: t('validations.required') },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => {
                                // console.log('file: index.tsx:150 ~ error:', error);

                                return (
                                    <RenderDatePickerField
                                        maxDate={dayjs()}
                                        minDate={dayjs(sessionData?.warrantyStartDate)}
                                        onChange={onChange}
                                        error={!!error}
                                        label={t('claim.incident_date')}
                                        value={value ?? null}
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '1rem' }}>
                        <Controller
                            name="invoiceDate"
                            control={control}
                            rules={{
                                required: { value: true, message: t('validations.required') },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <RenderDatePickerField maxDate={dayjs()} onChange={onChange} error={!!error} label={t('claim.invoice_date')} value={value ?? null} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ padding: '1rem' }}>
                        <Controller
                            name="customerPhone"
                            control={control}
                            rules={{
                                required: t('validations.required'),
                                validate: {
                                    isValidPhoneNumber: (value) => {
                                        if (!/^32\d{9}$/.test(value)) {
                                            return t('validations.phone');
                                        }
                                        return true;
                                    },
                                },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <RenderTextField icon={<PhoneIcon />} error={!!error} helperText={error?.message ?? null} onChange={onChange} value={value ?? ''} label={t('profile.customer_phone')} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ padding: '1rem' }}>
                        <Controller
                            name="invoiceAmount"
                            control={control}
                            rules={{
                                required: { value: true, message: t('validations.required') },
                                validate: {
                                    isValidAmount: (value: any) => {
                                        if (!/^\d+[\.|,]?\d{1,2}?$/.test(value)) {
                                            return t('validations.amount');
                                        }
                                        return true;
                                    },
                                },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => {
                                return (
                                    <RenderTextField
                                        icon={<MoneyIcon />}
                                        error={!!error}
                                        helperText={error?.message ?? null}
                                        onChange={onChange}
                                        value={value ?? ''}
                                        label={t('claim.invoice_amount')}
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ padding: '1rem' }}>
                        <Controller
                            name="bankAccount"
                            control={control}
                            rules={{
                                required: { value: true, message: t('validations.required') },
                                validate: {
                                    validateIban: validateRealIBAN,
                                    validateRealIban: validateRealIBAN,
                                },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => {
                                return (
                                    <RenderTextField
                                        icon={<BankIcon />}
                                        value={value || ''}
                                        error={!!error}
                                        helperText={error ? t('validations.invalid_iban') : null}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        label={t('claim.bank_account')}
                                    />
                                );
                            }}
                        />
                    </Grid>
                    {fileInvoice ? (
                        <Grid item xs={12} md={6} sx={{ padding: '1rem' }}>
                            <Controller
                                name="fileQuotation"
                                control={control}
                                rules={{
                                    required: { value: true, message: t('validations.required') },
                                    validate: {
                                        lessThan5MB: (value: any) => (value != null && value?.file?.size < 5242880) || 'Max 5MB',
                                    },
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <>
                                        <RenderFileInputField
                                            icon={<QuotationIcon />}
                                            name="fileQuotation"
                                            helperText={errors?.fileQuotation?.message}
                                            error={!!error}
                                            onChange={onChange}
                                            value={value || ''}
                                            label={t('claim.upload_quotation')}
                                        />
                                        {errors?.fileQuotation ? (
                                            <small style={{ marginLeft: '14px', color: '#d32f2f', marginTop: '5px' }}>{`${
                                                value?.name != '' ? errors?.fileQuotation?.message : t('validations.required')
                                            }`}</small>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                )}
                            />
                        </Grid>
                    ) : null}
                    {!fileRepairInvoice ? (
                        <Grid item xs={12} md={6} sx={{ padding: '1rem' }}>
                            <Controller
                                name="fileInvoice"
                                control={control}
                                rules={{
                                    required: { value: true, message: t('validations.required') },
                                    validate: {
                                        lessThan5MB: (value: any) => (value != null && value?.file?.size < 5242880) || 'Max 5MB',
                                    },
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <>
                                        <RenderFileInputField
                                            icon={<InvoiceIcon />}
                                            name="fileInvoice"
                                            helperText={errors?.fileInvoice?.message}
                                            error={!!error}
                                            onChange={onChange}
                                            value={value || ''}
                                            label={t('claim.upload_invoice')}
                                        />
                                        {errors?.fileInvoice ? (
                                            <small style={{ marginLeft: '14px', color: '#d32f2f', marginTop: '5px' }}>{`${
                                                value?.name != '' ? errors?.fileInvoice?.message : t('validations.required')
                                            }`}</small>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                )}
                            />
                        </Grid>
                    ) : null}
                    {!fileInvoice ? (
                        <Grid item xs={12} md={6} sx={{ padding: '1rem' }}>
                            <Controller
                                name="fileRepairInvoice"
                                control={control}
                                rules={{
                                    required: { value: true, message: t('validations.required') },
                                    validate: {
                                        lessThan5MB: (value: any) => (value != null && value?.file?.size < 5242880) || 'Max 5MB',
                                    },
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <>
                                        <RenderFileInputField
                                            icon={<RepairInvoiceIcon />}
                                            name="fileRepairInvoice"
                                            helperText={errors?.fileRepairInvoice?.message}
                                            error={!!error}
                                            onChange={onChange}
                                            value={value || ''}
                                            label={t('claim.upload_repair_invoice')}
                                        />
                                        {errors?.fileRepairInvoice ? (
                                            <small style={{ marginLeft: '14px', color: '#d32f2f', marginTop: '5px' }}>{`${
                                                value?.name != '' ? errors?.fileRepairInvoice?.message : t('validations.required')
                                            }`}</small>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                )}
                            />
                        </Grid>
                    ) : null}
                    <Grid item xs={12} sx={{ padding: '1rem' }}>
                        <Controller
                            name="claimDescription"
                            control={control}
                            rules={{
                                required: { value: true, message: t('validations.required') },
                                minLength: { value: 20, message: t('validations.description_length') },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <RenderTextField
                                    icon={<DescriptionIcon />}
                                    error={!!error}
                                    helperText={errors?.claimDescription?.message}
                                    onChange={onChange}
                                    value={value || ''}
                                    label={t('claim.claim_description')}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ padding: '1rem' }}>
                        <FormControl size={'small'} variant={'outlined'}>
                            <div>
                                {options.map((option: any) => {
                                    return (
                                        <Box key={option.value}>
                                            <FormControlLabel
                                                required
                                                label={option?.label}
                                                control={
                                                    <Controller
                                                        name={option?.name}
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: t('validations.required') },
                                                        }}
                                                        render={({ field }) => <Checkbox {...field} />}
                                                    />
                                                }
                                                // label={option.label}
                                                key={option.value}
                                            />
                                            {errors?.[option?.name] && (
                                                <Typography
                                                    sx={{ margin: '3px 14px 0 14px !important', fontSize: '0.75rem' }}
                                                    variant="body1"
                                                    className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
                                                >
                                                    {t('validations.required')}
                                                </Typography>
                                            )}
                                        </Box>
                                    );
                                })}
                            </div>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'} sx={{ margin: '1rem 0' }}>
                    <Grid item xs={10} md={5} sx={{ margin: '.5rem 0' }}>
                        <Link to="/dashboard">
                            <Button className="btn__secondary">{t('layout.dashboard')}</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={10} md={5} sx={{ margin: '.5rem 0', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button className="btn__primary" type="submit">
                            {t('claim.submit')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
        </Box>
    );
};

export default newClaim;
