import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Box, Divider, ListItem, ListItemText, ListSubheader } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ClaimHistoryIcon from '@mui/icons-material/AutoAwesomeMotion';
import ProfileIcon from '@mui/icons-material/AccountBox';
import ManageProfileIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
import PrivacyIcon from '@mui/icons-material/PrivacyTip';
import { useDispatch } from 'react-redux';
import { LOG_OUT } from '@src/redux/auth/actions';

const Sidebar: React.FC = () => {
    // const [open, setOpen] = useState(true);
    const { t } = useTranslation('common');
    const dispatchStore = useDispatch();
    const [contract, setContract] = useState<Array<any>>([]);
    const lanugaeId = localStorage.getItem('language') as string;
    useEffect(() => {
        //setUserData(JSON.parse(sessionStorage.getItem('contract')!)[0]);
        setContract(JSON.parse(sessionStorage.getItem('contract')!));
    }, []);
    const handleLogout = () => {
        dispatchStore({ type: LOG_OUT });
    };
    // contract[0]?.contract.customername;
    return (
        <List
            sx={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', maxWidth: { md: 360 }, bgcolor: '#ddd' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: 'black',
                        color: 'rgb(225, 225, 225) ',
                        borderRadius: '0px',
                        paddingLeft: { xs: '5px', md: '40px', xl: '5px' },
                        height: '20px',
                    }}
                    component="div"
                    id="nested-list-subheader"
                >
                    {/* <ProfileIcon sx={{ color: '#d9b700' }} /> */}
                    <Box sx={{ marginLeft: '.5rem', color: '#d9b700' }} component="span">
                        {contract[0]?.contract?.customername}
                    </Box>
                </ListSubheader>
            }
        >
            <Box sx={{ bgcolor: '#ddd' }}>
                <Link to="/dashboard">
                    <ListItemButton>
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('layout.dashboard')} />
                    </ListItemButton>
                </Link>
                <Link to="/claim-history">
                    <ListItemButton>
                        <ListItemIcon>
                            <ClaimHistoryIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('layout.claim_history')} />
                    </ListItemButton>
                </Link>
                {/* <Link to="/profile">
                    <ListItemButton>
                        <ListItemIcon>
                            <ManageProfileIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('layout.profile')} />
                    </ListItemButton>
                </Link> */}
                <Link to="/" onClick={handleLogout}>
                    <ListItemButton>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('layout.logout')} />
                    </ListItemButton>
                </Link>
                <Divider />
                <Link to={`/pdf/privacy_and_cooky_policy_${lanugaeId?.toUpperCase()}.pdf`} target="_blank">
                    <ListItemButton>
                        <ListItemIcon>
                            <PrivacyIcon sx={{ color: '#ad0109' }} />
                        </ListItemIcon>
                        <ListItemText sx={{ color: '#ad0109' }} primary={t('layout.privacy_policy')} />
                    </ListItemButton>
                </Link>
            </Box>
        </List>
    );
};

export default Sidebar;
